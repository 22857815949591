/* TeamMemberComponent.css */

.team-member-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000; /* Black background */
    color: white;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Hide overflow for cleaner navigation area edges */
    margin: 0;
  }
  
  .team-member {
    text-align: center;
    transition: transform 0.3s ease;
    width: 100%;
    max-width: 600px; /* Maximum width of team member info */
    padding: 20px;
    box-sizing: border-box;
  }
  .team-member img {
    width: 35%; /* Explicit width */
    max-width: 300px; /* Maximum size limit */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin: 0 auto;
    display: block;
  }
  
  .team-member:hover {
    transform: scale(1.05); /* Slight zoom on hover */
  }
  
  .team-member img {
    max-width: 100%; /* Responsive image */
    height: auto;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
  }
  
  .team-member h3 {
    margin-top: 20px;
    font-size: 2rem; /* Large font size for name */
  }
  
  .role-description {
    font-weight: bold; /* Make the role description bold */
    margin-bottom: 10px; /* Add some spacing between paragraphs */
    margin-top: 10px;
    font-size: 1.3rem; /* Readable font size */
  }

  .member-description  {
    margin-top: 10px;
    font-size: 1.2rem; /* Readable font size */
  }
  
  /* Invisible navigation areas */
  .navigation-area {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 25%; /* Half of the container */
    height: 100%;
    cursor: pointer;
    z-index: 5;
  }
  
  .navigation-area.left {
    left: 0;
  }
  
  .navigation-area.right {
    right: 0;
  }
  
  @media only screen and (max-width: 600px) {
    .team-member-container {
      padding: 20px;
    }
  
    .team-member img {
        width: 25%; /* Explicit width */
    }
  
    .team-member h3,
    .team-member p {
      font-size: 1rem; /* Smaller font size on smaller screens */
    }
  }
  