/* ContactPage.css */

.contact-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    text-align: center;
    background-color: #f4f4f4; /* Light background for a clean look */
    color: #333; /* Dark text for contrast */
    font-family: 'Arial', sans-serif; /* Modern, readable font */
    padding: 20px;
    opacity: 0; /* Set initial opacity to 0 for fade-in effect */
    animation: fadeIn 1.5s ease forwards; /* Apply fade-in animation */
  }

  
  .contact-page h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #0077cc; /* Slightly colored for visual interest */
  }
  
  .contact-page p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  .contact-page a {
    color: #0077cc;
    text-decoration: none;
  }
  
  .contact-page a:hover {
    text-decoration: underline;
  }
  
  .qr-code-container {
    border: 1px solid #ddd; /* Subtle border around QR code */
    padding: 10px;
    border-radius: 8px; /* Rounded corners for the container */
    background-color: white; /* White background to highlight QR code */
  }
  
  .qr-code-container img {
    max-width: 200px; /* Adjust QR code size */
    height: auto;
    border-radius: 4px; /* Slightly rounded corners for the QR code image */
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px) {
    .contact-page h1 {
      font-size: 2rem;
    }
  
    .qr-code-container img {
      max-width: 150px;
    }
  }
  