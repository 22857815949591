/* SecondPage.css */

.second-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vh; /* Adjusted from 100vh to 66vh to make it shorter */
    background-color: rgb(43, 35, 35);
    color: white;
    padding: 50px;
    box-sizing: border-box;
    text-align: center;
  }
  
  .text-container {
    max-width: 1200px; /* Or your preferred max width */
    margin: 0 auto;
  }
  
  .text-container p {
    font-size: 1.8rem; /* Or the size you prefer */
    line-height: 1.6; /* Adjust for readability */
    margin: 0;
    font-weight: bold; /* Make text bold */
    /* Fade-in animation remains unchanged */
    opacity: 0;
    animation: fadeIn 2s ease forwards;
    animation-delay: 0.5s;
  }
  
  /* Fade in animation */
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 600px) {
    .second-page {
      padding: 20px; /* Reduced padding */
      height: auto; /* Adjust height to fit content */
    }
  
    .text-container {
      max-width: 90%; /* Adjust max-width for smaller screens */
    }
  
    .text-container p {
      font-size: 1rem; /* Smaller font size for readability */
      line-height: 1.4; /* Adjust line height for smaller font */
    }
  }