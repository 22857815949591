/* AboutPage.css */

.about-page {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  max-width: 1000px;
  margin: 20px auto;
  margin-top: 0%;
  background: #fff;
}
.about-page h1:before {
  content: '';
  width: 100%;
  height: 1em;
  display: inline-block;
  background: rgb(255, 255, 255); /* Remove, just showing empty line */
}
.about-page h1 {
  text-align: center;
  color: #0077cc;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.about-page h2 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.about-page section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  background-color: #eef2f7;
}

/* Mission Statement */
.mission-statement {
  background-color: #f0e6f6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Our Services */
.our-services {
  background-color: #e8f0f6;
  border-top: 3px solid #bbb;
  border-bottom: 3px solid #bbb;
}

.our-services ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.our-services li {
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
}

.our-services li:last-child {
  border-bottom: none;
}

/* Fade in animation */
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

section {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-page {
    padding: 10px;
  }

  .about-page h1 {
    font-size: 2rem;
  }

  .about-page h2 {
    font-size: 1.5rem;
  }
  
  .mission-statement {
    padding: 15px;
  }
}
