/* FooterComponent.css */

.footer-container {
    text-align: center;
    color: #000; /* Black font color */
    background-color: #fff; /* White background */
    padding: 50px 20px;
    font-family: Arial, sans-serif; /* or your preferred font */
  }
  
  .footer-container h2 {
    margin-bottom: 20px;
    font-size: 2.5rem;
  }
  
  .footer-container p {
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .email-signup {
    margin-bottom: 30px;
  }
  
  .email-signup input {
    padding: 10px;
    margin-right: -4px; /* to counteract default spacing */
    border: 1px solid #ccc;
    border-right: none;
    color: #000; /* Black font color for input */
  }
  
  .email-signup button {
    padding: 11.2px 20px;
    background-color: #333; /* Darker background for button */
    color: white; /* White text for button */
    border: none;
    cursor: pointer;
  }
  
  .social-media a {
    margin: 0 30px;
    color: #000; /* Black font color for icons */
    font-size: 4rem; /* Adjust size as needed */
    text-decoration: none;
  }
  
  footer {
    margin-top: 60px;
    font-size: 0.5rem;
  }
  
  /* Ensure the FontAwesome icons (if used) are also styled to match the black font color */
  .social-media i {
    color: #000; /* Black color for font icons */
  }
  
  /* Additional hover effect for social media icons for user feedback */
  .social-media a:hover {
    color: #555; /* Dark grey for hover state */
  }
  .social-media :hover{
    transform: scale(1.1);
  }
  .social-media img {
    width: 50px; /* Or any size you prefer */
    height: auto; /* This will maintain the aspect ratio */
  }

  /* Responsiveness: Adjust the input and button size on smaller screens */
  @media only screen and (max-width: 600px) {
    .email-signup input,
    .email-signup button {
      width: 90%;
      margin: 10px 0; /* Stack the elements with some spacing */
      border-right: 1px solid #ccc;
    }
    
    .social-media a {
      margin: 5px; /* Reduced margin for smaller screens */
      font-size: 3rem; /* Slightly smaller icons for smaller screens */
    }
  }
  