.join-page {
    font-family: 'Arial', sans-serif;
    text-align: center;
    height: 100vh;
    background-color: #dde3e8; /* Updated background color */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #333;
    transition: all 0.3s ease;
    opacity: 0; /* Initial state for fade-in */
    animation: fadeIn 1s ease forwards; /* Animation for fade-in effect */
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    max-width: 800px;
    margin: auto;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .join-page h1, .join-page p {
    margin-bottom: 1rem;
    transition: color 0.3s ease, margin 0.3s ease;
  }
  
  .join-page h1 {
    font-size: 2.5rem;
    color: #0077cc;
  }
  
  .join-page p {
    font-size: 1.2rem;
  }
  
  .join-page a {
    color: #0077cc;
    text-decoration: none;
    font-weight: bold;
    margin: 0.5rem 0;
  }
  
  .join-page a:hover {
    text-decoration: underline;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @media (max-width: 600px) {
    .join-page h1 {
      font-size: 2rem; /* Smaller font size for h1 on smaller screens */
    }
    
    .join-page p {
      font-size: 1rem; /* Smaller font size for paragraphs on smaller screens */
    }
  }
  