/* MainHero.css */

.main-hero {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 0;
  }
  
  .content {
    position: relative;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 2s ease forwards;
    animation-delay: 0.5s;
  }
  
  .title {
    font-size: 4.5rem; /* Adjust the size as needed */
    margin-bottom: 1rem;
  }
  
  .cta-button {
    background: transparent;
    border: 2px solid white;
    color: white;
    padding: 10px 30px;
    font-size: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    opacity: 0;
    animation: fadeIn 2s ease forwards;
    animation-delay: 1s; /* Delay the fade-in effect to be after the title */
  
    /* Create angled corners using clip-path */
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
  }
  
  .cta-button:hover {
    background-color: white;
    color: black;
  }
  .survey-button {
    background: transparent;
    border: 2px solid white;
    color: white;
    padding: 8px 20px; /* Smaller padding than cta-button */
    font-size: 1.2rem; /* Smaller font size than cta-button */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: block;
    margin: 10px auto;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%); /* Angled corners */
    opacity: 0;
    animation: fadeIn 2s ease forwards;
    animation-delay: 1.5s;
  }
  
  .survey-button:hover {
    background-color: white;
    color: black;
  }
  /* Fade in animation */
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 600px) {
    .main-hero h1 {
      font-size: 1.5rem; /* Smaller text for the main hero title */
    }
  
    .main-hero .cta-button {
      padding: 8px 16px; /* Smaller padding for the button */
      font-size: 1rem; /* Smaller font size for the button text */
    }
    .main-hero .survey-button {
      padding: 6px 10px; /* Smaller padding for the button */
      font-size: 0.8rem; /* Smaller font size for the button text */
    }
  }
  